import { ClassType } from '../models/enums/class.type';

export enum SkinSlot {
  Body = 'body',
  LeftHand = 'left_hand',
  RightHand = 'right_hand'
}

/**
 * Gets the skin slot based on the spine resource name.
 * In case the skin name is not properly identified, it returns "Body"
 * @param spineResourceName The skin name
 */
export function getSkinSlot(spineResourceName: string): SkinSlot {
  return [SkinSlot.LeftHand, SkinSlot.RightHand, SkinSlot.Body]
    .find(slot => isValidSkinForSlot(slot, spineResourceName)) ?? SkinSlot.Body;
}


export function isValidSkinForSlot(slot: SkinSlot, skin: string): boolean {
  return skin.indexOf(slot) !== -1;
}

export function isValidSkinForClassType(classType: ClassType, skin: string, uniqueSkins: Record<string, boolean>): boolean {
  if (!skin || typeof skin !== 'string')
    return false;

  const isSameClassType = skin.includes(classType);

  if (!isSameClassType)
    return false;

  const nameParts = skin.split('_');

  nameParts.pop();
  nameParts.pop();

  const uniqueSkinName = nameParts.join('_');

  if (uniqueSkins[uniqueSkinName])
    return false;

  uniqueSkins[uniqueSkinName] = true;

  return true;
}

export function cleanSkinsForClassType(classType: ClassType, skins: string[]): string[] {
  const uniqueSkins: Record<string, boolean> = {};

  let selectedSkins = skins.filter(skin => isValidSkinForClassType(classType, skin, uniqueSkins));

  if (selectedSkins.length === 0)
    selectedSkins = ['default'];

  if (classType == ClassType.Mage && selectedSkins.findIndex(s => isValidSkinForSlot(SkinSlot.LeftHand, s)) === -1)
    selectedSkins = [...selectedSkins, 'mage_left_hand_a_1'];

  if (classType == ClassType.Archer && selectedSkins.findIndex(s => isValidSkinForSlot(SkinSlot.LeftHand, s)) === -1)
    selectedSkins = [...selectedSkins, 'archer_left_hand_a_1'];

  return selectedSkins;
}

export function removeSkinsForSkinSlot(classType: ClassType, skinSlot: SkinSlot, skins: string[]): string[] {
  const uniqueSkins: Record<string, boolean> = {};

  return skins.filter(skin =>
    isValidSkinForClassType(classType, skin, uniqueSkins) && !isValidSkinForSlot(skinSlot, skin)
  );
}
