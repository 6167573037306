import styled from '@emotion/styled';
import { noWrap, toEm } from '../../styles/functions';
import scPattern from '../../assets/images/inline/sc_pattern.png';
import { css } from '@emotion/react';
import frameBorder from '../../assets/images/inline/frame_border.png';
import frameLineRepeat from '../../assets/images/inline/modal_frame_line_repeat.png';
import frameCorner from '../../assets/images/inline/modal_frame_top_corner.png';
import buttonExit from '../../assets/images/inline/button_exit.png';
import { hideDrag } from '../../styles/hide-drag';

export type ModalProps = {
  paddingTop: number;
}

const minModalWidth = 25 * 16;
const minModalHeight = 20 * 16;

const frameBottomIconHeight = 7 * 16;
const frameBottomIconWidth = 66 * frameBottomIconHeight / 120;

const frameLineHeight = 2.5 * 16;

const frameHeaderTextHeight = 5 * 16;
const frameHeaderTextWidth = 358 * frameHeaderTextHeight / 107;

const frameHeaderTextLargeHeight = 5 * 16;
const frameHeaderTextLargeWidth = 579 * frameHeaderTextHeight / 105;

const frameHeaderLogoHeight = 11 * 16;
const frameHeaderLogoWidth = 301 * frameHeaderLogoHeight / 220;

const frameCornerHeight = 2.5 * 16;
const frameCornerWidth = 156 * frameCornerHeight / 45;

export const Modal = styled.div<ModalProps>`
  min-height: ${ toEm(minModalHeight) };
  min-width: ${ toEm(minModalWidth) };

  position: relative;

  padding: ${ props => props.paddingTop }em 2.5em 4em 2.5em;

  background: url(${ scPattern }) repeat center;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ButtonExit = styled.button`
  position: absolute;
  top: -2em;
  right: -1.3em;
  z-index: 30;

  appearance: none;
  outline: none;
  border: none;
  cursor: pointer;

  background-color: transparent;
  background-image: url("${ buttonExit }");
  background-size: 100% 100%;

  height: 4em;
  width: 4em;
`;

export const FrameTopTitle = styled.h1`
  position: absolute;
  left: calc(50% - ${ toEm(frameHeaderTextWidth * .8 / 2) } + 1em);
  top: -${ toEm(frameHeaderTextHeight / 5) };

  width: calc(${ toEm(frameHeaderTextWidth * .8) } - 2em);

  text-align: center;
  font-size: 1.2em;
  line-height: 2.2em;

  color: #000;

  z-index: 30;

  ${ hideDrag };
  ${ noWrap(`calc(${ toEm(frameHeaderTextWidth * .8) } - 2em)`) }
`;

export const HeaderTypeText = styled.img`
  width: ${ toEm(frameHeaderTextWidth) };
  height: ${ toEm(frameHeaderTextHeight) };

  position: absolute;

  left: calc(50% - ${ toEm(frameHeaderTextWidth / 2) });
  top: -${ toEm(frameHeaderTextHeight / 2) };

  z-index: 20;
`;

export const FrameTopTitleLarge = styled.h1`
  position: absolute;
  left: calc(50% - ${ toEm(frameHeaderTextLargeWidth * .8 / 2) } + 1em);
  top: -${ toEm(frameHeaderTextLargeHeight / 5.5) };

  width: calc(${ toEm(frameHeaderTextLargeWidth * .8) } - 2em);

  text-align: center;
  font-size: 1.2em;
  line-height: 1.6em;

  color: #000;

  z-index: 30;

  ${ hideDrag };
  ${ noWrap(`calc(${ toEm(frameHeaderTextLargeWidth * .8) } - 2em)`) }
`;

export const HeaderTypeTextLarge = styled.img`
  width: ${ toEm(frameHeaderTextLargeWidth) };
  height: ${ toEm(frameHeaderTextLargeHeight) };

  position: absolute;

  left: calc(50% - ${ toEm(frameHeaderTextLargeWidth / 2) });
  top: -${ toEm(frameHeaderTextLargeHeight / 2) };

  z-index: 20;
`;

export const HeaderTypeLogo = styled.img`
  width: ${ toEm(frameHeaderLogoWidth) };
  height: ${ toEm(frameHeaderLogoHeight) };

  position: absolute;

  left: calc(50% - ${ toEm(frameHeaderLogoWidth / 2) });
  top: -${ toEm(frameHeaderLogoHeight / 3) };

  z-index: 20;
`;

export const BaseFrameLine = css`
  width: 100%;
  height: ${ toEm(frameLineHeight) };

  position: absolute;
  left: 0;

  background-size: contain;
  background-image: url("${ frameLineRepeat }");
  background-repeat: repeat-x;

  z-index: 10;
`;

export const FrameTopLine = styled.div`
  ${ BaseFrameLine };

  bottom: -${ toEm(frameLineHeight / 2) };
`;

export const FrameBottomLine = styled.div`
  ${ BaseFrameLine };

  top: -${ toEm(frameLineHeight / 2) };
`;

export const FrameBottomIcon = styled.img`
  width: ${ toEm(frameBottomIconWidth) };
  height: ${ toEm(frameBottomIconHeight) };

  position: absolute;
  left: calc(50% - ${ toEm(frameBottomIconWidth / 2) });
  bottom: -${ toEm(frameBottomIconHeight / 2) };

  z-index: 20;
`;

const FrameBorder = css`
  position: absolute;
  top: 0;
  z-index: 5;

  width: 1.5em;
  height: 100%;

  background-image: url("${ frameBorder }");
  background-repeat: repeat-y;
  background-size: contain;
`;

export const FrameLeft = styled.div`
  ${ FrameBorder };

  left: 0;
  background-position-x: left;
`;

export const FrameRight = styled.div`
  ${ FrameBorder };

  right: 0;
  background-position-x: right;
`;

export const BaseCorner = css`
  position: absolute;

  background-image: url("${ frameCorner }");
  background-size: contain;
  background-repeat: no-repeat;

  height: ${ toEm(frameCornerHeight) };
  width: ${ toEm(frameCornerWidth) };

  z-index: 10;
`;

export const FrameCornerTopLeft = styled.div`
  ${ BaseCorner };

  left: -4em;
  top: -1.37em;
`;

export const FrameCornerTopRight = styled.div`
  ${ BaseCorner };

  right: -4em;
  top: -1.37em;

  transform: rotateZ(180deg) rotateX(180deg);
`;

export const FrameCornerBottomLeft = styled.div`
  ${ BaseCorner };

  left: -4em;
  bottom: -1.27em;
`;

export const FrameCornerBottomRight = styled.div`
  ${ BaseCorner };

  right: -4em;
  bottom: -1.27em;

  transform: rotateZ(180deg) rotateX(180deg);
`;
