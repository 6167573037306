import { ReactElement, ReactNode } from 'react';

import frameBottomIcon from '../../assets/images/inline/modal_frame_bottom_icon.png';
import headerText from '../../assets/images/inline/modal_frame_header_text.png';
import headerTextLarge from '../../assets/images/inline/modal_frame_header_text_large.png';
import headerLogo from '../../assets/images/inline/modal_frame_header_logo.png';

import * as S from './styles';

import { hideDragJs } from '../../styles/hide-drag';
import useRandomSound from '../../hooks/useRandomSound';
import { soundEnvironment } from '../../environments/sound';

export type SCBaseModalFrameProps = {
  className?: string;
  onClose?: () => void;
  children: ReactNode;
}

export type SCModalFrameHeaderText = SCBaseModalFrameProps & {
  headerType: 'text' | 'textLarge';
  title: string;
}

export type SCModalFrameHeaderLogo = SCBaseModalFrameProps & {
  headerType: 'logo';
  title?: never;
}

export type SCModalFrameProps = SCModalFrameHeaderText | SCModalFrameHeaderLogo;

function SCModalFrame({ headerType, className, children, title, onClose }: SCModalFrameProps): ReactElement {
  useRandomSound(soundEnvironment.modals, { playOnMount: true });

  return (
    <S.Modal className={className}
             paddingTop={headerType === 'logo' ? 8 : 4}
             {...hideDragJs}>

      {headerType === 'text' && (<>
        <S.FrameTopTitle>{title.toUpperCase()}</S.FrameTopTitle>

        <S.HeaderTypeText src={headerText} alt={title}/>
      </>)}

      {headerType === 'textLarge' && (<>
        <S.FrameTopTitleLarge>{title.toUpperCase()}</S.FrameTopTitleLarge>

        <S.HeaderTypeTextLarge src={headerTextLarge} alt={title}/>
      </>)}

      {headerType === 'logo' && (
        <S.HeaderTypeLogo src={headerLogo} alt="O icone do Mundo Facens"/>
      )}

      <S.FrameBottomIcon src={frameBottomIcon} alt="A moldura da modal no canto inferior."/>

      <S.FrameTopLine/>
      <S.FrameBottomLine/>

      <S.FrameCornerTopLeft/>
      <S.FrameCornerTopRight/>
      <S.FrameCornerBottomLeft/>
      <S.FrameCornerBottomRight/>

      <S.FrameLeft/>
      <S.FrameRight/>

      {!!onClose && (
        <S.ButtonExit onClick={onClose}/>
      )}

      {children}
    </S.Modal>
  );
}

export default SCModalFrame;
