import Spin from 'antd/lib/spin';
import { ButtonHTMLAttributes, MouseEvent, ReactElement } from 'react';

import buttonRound from '../../assets/images/inline/button_round.png';
import buttonSquare from '../../assets/images/inline/button_square.png';
import buttonTrue from '../../assets/images/inline/button_true.png';
import buttonFalse from '../../assets/images/inline/button_false.png';
import buttonLarge from '../../assets/images/inline/button_large.png';
import buttonLargeRound from '../../assets/images/inline/button_large_round.png';
import useRandomSound from '../../hooks/useRandomSound';

import * as S from './styles';
import { soundEnvironment } from '../../environments/sound';

export interface SCButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  shape: 'round' | 'square' | 'true' | 'false' | 'large' | 'large_round';
  loading?: boolean;
  primary?: boolean;
  wrap?: boolean;
}

function getImageButtonFromType(shape: SCButtonProps['shape']): string {
  if (shape === 'round')
    return buttonRound;

  if (shape === 'true')
    return buttonTrue;

  if (shape === 'false')
    return buttonFalse;

  if (shape === 'large')
    return buttonLarge;

  if (shape === 'large_round')
    return buttonLargeRound;

  return buttonSquare;
}

function SCButton({ shape, loading, disabled, onClick, primary, wrap, children, ...props }: SCButtonProps): ReactElement {
  const play = useRandomSound(soundEnvironment.genericButtons, { volume: .2 });

  function onClickOnButton(event: MouseEvent<HTMLButtonElement>) {
    if (disabled || loading)
      return;

    if (!onClick)
      return;

    play();
    onClick(event);
  }

  return (
    <S.Button {...props}
              onClick={onClickOnButton}
              disabled={disabled}
              loading={loading}>
      <S.ButtonImage src={getImageButtonFromType(shape)} wrapWidth={!!wrap}/>
      <S.ButtonContent>
        <S.TextContainer primary={!!primary}>
          <Spin spinning={!!loading}>
            <S.Text whitespace={wrap ? 'auto' : 'nowrap'}>{children}</S.Text>
          </Spin>
        </S.TextContainer>
      </S.ButtonContent>
    </S.Button>
  );
}

export default SCButton;
