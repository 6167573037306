import { css, Global } from '@emotion/react';
import React from 'react';

import 'leaflet/dist/leaflet.css';
import './antd.less';
import { theme } from '../App.theming';

export const GlobalStyles: React.FunctionComponent = () => (
  <>
    <Global
      styles={css`
        @font-face {
          font-family: 'ClanOT';
          src: url(/assets/fonts/ClanOT-Bold.woff2) format('woff2'),
          url(/assets/fonts/ClanOT-Bold.woff) format('woff');
          font-weight: bold;
          font-style: normal;
          font-display: swap;
        }
        
        @font-face {
          font-family: 'ClanOT';
          src: url(/assets/fonts/ClanOT-Book.woff2) format('woff2'),
          url(/assets/fonts/ClanOT-Book.woff) format('woff');
          font-weight: normal;
          font-style: normal;
          font-display: swap;
        }

        @font-face {
          font-family: 'ClanOT';
          src: url(/assets/fonts/ClanOT-Medium.woff2) format('woff2'),
          url(/assets/fonts/ClanOT-Medium.woff) format('woff');
          font-weight: 500;
          font-style: normal;
          font-display: swap;
        }

        @font-face {
          font-family: 'ClanOT-Narrow';
          src: url(/assets/fonts/ClanOT-NarrowBold.woff2) format('woff2'),
          url(/assets/fonts/ClanOT-NarrowBold.woff) format('woff');
          font-weight: bold;
          font-style: normal;
          font-display: swap;
        }

        @font-face {
          font-family: 'ClanOT-Narrow';
          src: url(/assets/fonts/ClanOT-NarrowMedium.woff2) format('woff2'),
          url(/assets/fonts/ClanOT-NarrowMedium.woff) format('woff');
          font-weight: 500;
          font-style: normal;
          font-display: swap;
        }

        @font-face {
          font-family: 'ClanOT-Narrow';
          src: url(/assets/fonts/ClanOT-NarrowBook.woff2) format('woff2'),
          url(/assets/fonts/ClanOT-NarrowBook.woff) format('woff');
          font-weight: normal;
          font-style: normal;
          font-display: swap;
        }
        
        :root {
          --text-font-family: 'ClanOT', sans-serif;
        }

        *,
        *:before,
        *:after {
          box-sizing: border-box;
        }

        html,
        body,
        div,
        span,
        object,
        iframe,
        figure,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        blockquote,
        pre,
        a,
        code,
        em,
        img,
        small,
        strike,
        strong,
        sub,
        sup,
        tt,
        b,
        u,
        i,
        ol,
        ul,
        li,
        fieldset,
        form,
        label,
        table,
        caption,
        tbody,
        tfoot,
        thead,
        tr,
        th,
        td,
        main,
        canvas,
        embed,
        footer,
        header,
        nav,
        section,
        video {
          margin: 0;
          padding: 0;
          border: 0;
          font-size: 100%;
          font-family: 'ClanOT-Narrow', 'ClanOT', sans-serif;
          vertical-align: baseline;
          text-rendering: optimizeLegibility;
          -webkit-font-smoothing: antialiased;
          text-size-adjust: none;
        }

        footer,
        header,
        nav,
        section,
        main {
          display: block;
        }

        body {
          line-height: 1;
        }

        ol,
        ul {
          list-style: none;
        }

        blockquote,
        q {
          quotes: none;
        }

        blockquote:before,
        blockquote:after,
        q:before,
        q:after {
          content: '';
          content: none;
        }

        table {
          border-collapse: collapse;
          border-spacing: 0;
        }

        input {
          -webkit-appearance: none;
          border-radius: 0;
          border: none;
          outline: none;
        }

        .map-component-marker {
          background: transparent;
          border: 0;
        }

        .ant-modal {
          font-size: inherit;
        }

        @supports (padding-top: 20px) {
          html {
            --safe-area-top: var(--ion-statusbar-padding);
          }
        }

        @supports (padding-top: constant(safe-area-inset-top)) {
          html {
            --safe-area-top: constant(safe-area-inset-top);
            --safe-area-bottom: constant(safe-area-inset-bottom);
            --safe-area-left: constant(safe-area-inset-left);
            --safe-area-right: constant(safe-area-inset-right);
          }
        }

        @supports (padding-top: env(safe-area-inset-top)) {
          html {
            --safe-area-top: env(safe-area-inset-top);
            --safe-area-bottom: env(safe-area-inset-bottom);
            --safe-area-left: env(safe-area-inset-left);
            --safe-area-right: env(safe-area-inset-right);
          }
        }

        #game-modal-root .ant-modal-wrap, #game-modal-root .ant-modal-mask {
          left: ${theme.sidebarWidth};
          max-width: calc(100% - ${theme.sidebarWidth});

          .ant-modal {
            width: unset !important;
          }
        }

        .ant-notification-notice {
          border-radius: 20px;
          border: 4px solid ${theme.colors.primary700};
          background-color: ${theme.colors.primary950};

          .ant-notification-notice-with-icon {
            display: flex;
            flex-direction: column;
            justify-content: center;
          }

          .ant-notification-notice-icon {
            margin-left: -.5rem;
            margin-top: 0;
            top: calc(50% - 2.5rem);
          }

          .ant-notification-close-x {
            color: white;
          }

          .ant-notification-notice-message,
          .ant-notification-notice-description {
            color: white;

            margin-left: 76px;
          }
        }
      `}
    />
  </>
);
