import { useCallback, useEffect, useState } from 'react';

import useSound from '../useSound';
import { HookOptions, PlayFunction } from '../useSoundNative/types';

// eslint-disable-next-line react-hooks/rules-of-hooks
const useSoundHooks = (links, options) => links.map(url => useSound(url, options));

export type UseRandomSoundOptions = HookOptions & { playOnMount?: boolean };

export const RandomTableSound: Record<string, number> = {};

function useRandomSound(urls: string[], { playOnMount, ...hookOptions }: UseRandomSoundOptions = {}): PlayFunction {
  const [links, setLinks] = useState(() => urls);

  const hooks = useSoundHooks(links, hookOptions);
  const [hooksLoaded, setHooksLoaded] = useState([]);

  const playCallback: PlayFunction = useCallback(options => {
    if (!hooksLoaded.length)
      return;

    const urlHash = links.join('');

    RandomTableSound[urlHash] = RandomTableSound[urlHash] === undefined ? -1 : RandomTableSound[urlHash];

    const nextAudioIndex = ++RandomTableSound[urlHash];

    if (nextAudioIndex === hooksLoaded.length - 1)
      RandomTableSound[urlHash] = -1;

    hooksLoaded[nextAudioIndex][0](options);
  }, [links, hooksLoaded]);

  useEffect(() => {
    const alreadyLoaded = hooks.every(hook => !!hook[1].sound);

    if (!alreadyLoaded)
      return;

    if (hooksLoaded.length)
      return;

    setHooksLoaded(hooks);
  }, [hooksLoaded, setHooksLoaded, hooks]);

  useEffect(() => {
    setLinks(urls);
  }, [urls, setLinks]);

  useEffect(() => {
    if (!playOnMount)
      return;

    playCallback();
  }, [playCallback, playOnMount]);

  return playCallback;
}

export default useRandomSound;
