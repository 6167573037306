import { SkinSlot } from '../utils/skin';
import { ClassType } from '../models/enums/class.type';

export const environment = {
  defaultUnprotectedRoute: '/',
  defaultProtectedRoute: '/main',
  zustandStoreVersion: 3,
  config: {
    enableCoins: true,
    enableUpgrades: true,
    enableSkills: false,
    skinSlots: [
      {
        slot: SkinSlot.LeftHand,
        shortName: 'Controle',
        name: 'Controle',
      },
    ],
    classTypes: [
      ClassType.Mage,
      ClassType.Archer,
    ],
    classNames: {
      [ClassType.Mage]: {
        maleName: 'Gamer Boy',
        femaleName: 'Gamer Girl',
        data: {
          basePath: '/assets/spine/mage',
          spineJsonPath: 'Gamer_Boy.json',
          spineAtlasPath: 'Gamer_Boy.atlas',
          spineAssetsPath: [
            'Gamer_Boy.png',
          ],
        },
      },
      [ClassType.Archer]: {
        maleName: 'Gamer Girl',
        femaleName: 'Gamer Girl',
        data: {
          basePath: '/assets/spine/archer',
          spineJsonPath: 'Gamer_Girl.json',
          spineAtlasPath: 'Gamer_Girl.atlas',
          spineAssetsPath: [
            'Gamer_Girl.png',
          ],
        },
      },
    },
  },
  api: {
    baseUrl: process.env.REACT_APP_BASE_URL || 'https://api.vemprafacens.ligafacens.com/prod',
    auth: {
      local: '/auth/{authType}',
    },
    user: {
      me: '/users/me',
      create: '/users',
      selectClass: '/users/{userId}/class-type',
      update: '/users/{userId}',
      ranking: '/users/{userId}/ranking',
      listRanking: '/users/ranking',
      skins: '/users/skins?join=skin&limit=1000',
      buySkin: '/users/{userId}/skins/{skinId}',
      buySkinUpgrade: '/users/{userId}/skins/{skinId}/upgrades/{skinUpgradeId}',
    },
    quizes: {
      list: '/quizes?join=place&join[0]=questions&join[1]=questions.answers&limit=1000',
      sendAnswer: '/users/questions/{questionId}/answers',
    },
    learnStyles: {
      list: '/learn-styles?limit=1000',
      answers: '/learn-styles/answers',
    },
    odysseys: {
      list: '/odysseys?limit=1000',
      answers: '/odyssey-answers',
    },
    skins: {
      list: '/skins?s={searchParams}&limit=1000',
    },
  },
};
