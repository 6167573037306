import create, { UseStore } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { environment } from '../../environments/environment';
import { getStoreName } from '../utils/functions';
import { UseSoundStore } from './models';

export function createUseSoundStore(): UseStore<UseSoundStore> {
  const name = getStoreName('useSoundStore');

  return create(
    devtools(
      persist((set) => ({
        volume: .5,
        mute: () => set({ volume: 0 }),
        setVolume: (volume) => set({ volume }),
      }), { name, version: environment.zustandStoreVersion }),
      name,
    ),
  );
}
