import { useEffect, useState } from 'react';
import useFontSizeStore from '../../store/useFontSize';
import useSound from '../useSound';
import { soundEnvironment } from '../../environments/sound';

const MAX_VIEWPORT_WIDTH = 1250;
const MAX_VIEWPORT_HEIGHT = 650;
const MIN_SCALE = 0.25;

export interface ViewportScaleResult {
  scale: number;
  isScaleTooLow: boolean
}

export function useViewportScale(): ViewportScaleResult {
  const [scale, setScale] = useState(1);

  useEffect(() => {
    function onResize() {
      const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
      const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);

      const scale = Math.min(vw / MAX_VIEWPORT_WIDTH, vh / MAX_VIEWPORT_HEIGHT);

      setScale(Math.min(scale, 1));
    }

    window.addEventListener('resize', onResize);

    onResize();

    return () => window.removeEventListener('resize', onResize);
  }, []);

  return {
    scale,
    isScaleTooLow: scale < MIN_SCALE
  };
}

function useBackgroundMusic() {
  const [playBackground, background] = useSound(soundEnvironment.backgroundLoop, { interrupt: false, volume: 0.45 });

  useEffect(() => {
    if (background.isPlaying)
      return;

    playBackground();
  }, [playBackground, background.isPlaying]);
}

export default function useAppEffects(): void {
  // Background Music
  useBackgroundMusic();

  // Scaling
  const { scale } = useViewportScale();
  const fontSize = useFontSizeStore(state => state.fontSize);

  useEffect(() => {

    const html = document.querySelector('html');

    html.style.fontSize = `${fontSize * scale}px`;

  }, [fontSize, scale]);
}
