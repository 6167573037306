import styled from '@emotion/styled';


export const SCScreenLoading = styled.div`
  background: ${props => props.theme.colors.primary950};
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 2em;
`;
